import { createContext, useState } from 'react';

interface AlgoliaContextProps {
  children: React.ReactNode;
}

export type SearchContextType = {
  resultsSearch: any[];
  setResults: React.Dispatch<React.SetStateAction<any[]>>;
}

export type KeywordContextType = {
  keyword: string
  setKeyword: React.Dispatch<React.SetStateAction<string>>
}

export type ResultsInfoContextType = {
  resultsInfo: any[];
  setResultsInfo: React.Dispatch<React.SetStateAction<any[]>>;
}

export const SearchContext = createContext({} as SearchContextType)
export const KeywordContext = createContext({} as KeywordContextType)
export const ResultsInfoContext = createContext({} as ResultsInfoContextType)

const Algolia = (props: AlgoliaContextProps) => {
  const { children } = props;
  
  const [keyword, setKeyword] = useState('');
  const [resultsInfo, setResultsInfo] = useState([]) as any;
  const [resultsSearch, setResults] = useState([]) as any;

  return (
    <ResultsInfoContext.Provider value={{ resultsInfo, setResultsInfo }}>
      <KeywordContext.Provider value={{ keyword, setKeyword }}>
        <SearchContext.Provider value={{ resultsSearch, setResults }}>
          {children}
        </SearchContext.Provider>
      </KeywordContext.Provider>
    </ResultsInfoContext.Provider>
  );
};

export default Algolia;